.banner-btn-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 186px;
  border: 1px solid #D6B981;
  margin-top: 50px;
  padding: 3px;
  cursor: pointer;
}

.banner-btn-inner { 
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  font-size: 16px;
  font-family: OpenSans-Bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 22px;
  text-align: center;
  background: #D6B981;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
}

.banner-btn-inner:hover { 
  background-color: transparent;
  color: #D6B981;
}
