#quick-menu {
  height: 86px;
  width: 120px;
  position: fixed;
  bottom: 7.166666666666666VW;
  right: 0;
  z-index: 3;
  /* overflow: hidden;   */
  padding: 3px 0 3px 3px;
}

#quick-menu .row-outer {	
  height: 38px;	
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#quick-menu .row-outer:first-child { box-shadow: 0px 0px 30px 7px rgba(0,0,0,.1); }

#quick-menu .buy-blk {
  background-color: #FFFFFF;
  cursor: pointer;
}

#quick-menu .buy-blk .cart-icon {
	background: url('/assets/images/sprint_aroma_icons.svg') no-repeat -355px -15px;
	width: 16px;
  height: 16px;
  cursor: pointer;
}

#quick-menu .buy-blk p {
	color: #000000;
  font-family: OpenSans-SemiBold;
  text-transform: uppercase;
  /* font-size: 13px; */
  font-size: 10px;
	letter-spacing: 1px;
  /* line-height: 18px; */
  margin: 0;
  padding: 0;
  text-align: center;
}

#quick-menu .social-blk {
  margin-top: 0.5208333333333333VW;
  background-color: #EF2753;
}

#quick-menu .social-blk .inst-icon {
	background: url('/assets/images/sprint_aroma_icons.svg') no-repeat -386px -15px;
	width: 16px;
  height: 16px;
  cursor: pointer;  
}

#quick-menu .social-blk .fb-icon {
	background: url('/assets/images/sprint_aroma_icons.svg') no-repeat -416px -14px;
	width: 9px;
	height: 18px;
  cursor: pointer;
}