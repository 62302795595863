#ccav-block {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#ccav-block .ccav-box {
  margin-top: 60px;
}

#ccav-block .ccav-succ-title {
  font-family: OpenSans-Bold;
  font-size: 28px;
  color: #000;
  letter-spacing: 6px;
  line-height: 26px;
}

#ccav-block .ccav-succ-sub-title {
  font-family: OpenSans-Regular;
  font-size: 16px;
  color: #000;
  letter-spacing: 3px;
  line-height: 16px;
  padding: 30px 0 15px;
  opacity: 0.6;;
}

#ccav-block .ccav-ref-block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

#ccav-block .ccav-order-title,
#ccav-block .ccav-ref-title {
  font-family: OpenSans-SemiBold;
  font-size: 18px;
  color: rgba(0,0,0,0.8);
  letter-spacing: 4px;
  line-height: 26px;
}

#ccav-block .ccav-divider {
  height: 20px;
  border: 1px solid rgba(0,0,0,0.2);
  background-color: rgba(0,0,0,0.2);
  margin: 0 30px;
}

#ccav-block .ccav-go-back {
  font-family: OpenSans-Regular;
  font-size: 16px;
  color: #000;
  letter-spacing: 2px;
  line-height: 16px;
}

#ccav-block .ccav-loader-box {
  margin-top: 160px;
}

#ccav-block .ccav-loader {
  width: 80px;
  height: 80px;
}

#ccav-block .ccav-loader-title {
  font-family: OpenSans-SemiBold;
  font-size: 24px;
  color: rgba(0,0,0,0.7);
  letter-spacing: 5px;
  line-height: 26px;
  padding-top: 30px;
}

