/* #articledetails {
	position: fixed;
	height: 100%;
	right: 0px;
	left: 0px;
	overflow-y: scroll;
} */

#articledetails h1, #articledetails h2, #articledetails h3, #articledetails h4, #articledetails h5 {
  font-family: OpenSans-Light;
  color: rgba(0,0,0,0.7);
}

#articledetails .quote  {
  color: #333333 !important;
  font-family: GreycliffCF-RegularOblique !important;
  font-weight: bold;
  padding: 15px 20px;
  border-left: 4px solid red;
}

#articledetails .title {
    margin: 0 auto;
    /* width: fit-content; */
    text-align: center;
}

#articledetails .title-1,
#articledetails .title-2 {
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 66px;  
}

#articledetails .title-1 {
    color:   #333333;
    font-family: GreycliffCF-DemiBold;
    padding-right: 10px;  
}

#articledetails .title-2 {
    color: #00D2BB;
    font-family: blueliquidbold-webfont;
}

#articledetails .blog-desc div {
  /* color: #3B3B3B;
  font-family: GreycliffCF-DemiBold; */
  font-family: OpenSans-Light;
  color: rgba(0,0,0,0.7);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

#articledetails .blog-desc p {
   text-align: justify;
  font-family: GreycliffCF-Regular;
 }
#articledetails .article-block {
  padding: 1.925VW 13.208333333333334VW 0;
  min-height: 350px;
  padding-top: 150px;
  font-family: OpenSans-Light;
  color:rgba(0,0,0,0.7)
}

#articledetails .article-block  img { width: 100%; }

#articledetails .blog-title {
  /* color: #35CBB8;
  font-family: GreycliffCF-Bold; */
  font-family: OpenSans-Light;
  color: rgba(0,0,0,0.7);
  font-size: 28px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: left;
}

#articledetails .article-block .article-date,
#articledetails .article-block .article-author {
  color: #000000;
  font-family: GreycliffCF-RegularOblique;
  font-size: 14px;
  text-align: left;
  letter-spacing: 2px;	
  line-height: 19px;
}

#articledetails  .ban-head {
    padding: 1.925VW 13.208333333333334VW 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

#articledetails .content-body::-webkit-scrollbar{
  width: 6px;
  height: 10px;
  max-height: 20px;
}

#articledetails .content-body::-webkit-scrollbar-thumb {
  background: transparent; 
  border-radius: 20px;
  height: 10px;
  max-height: 20px;
}

#articledetails .content-body:hover::-webkit-scrollbar-thumb {
  /* background: #888; */
  /* width: 8px; */
  border-radius: 4px;
  background: rgba(0,0,0,0.3);
}

#articledetails .banner-block {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#articledetails .back-img {
  /* position: absolute;
  left: -15px; */
  transition: all .5s;
}

#articledetails .back-button-block:hover .back-img {
  transform: translateX(-10px);
 }

#articledetails .banner-desc {
	position: absolute;
	
}

#articledetails .banner-header {
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
  margin-top: 15px;
  position: absolute;
  top: 0;
  width: 100%;
}

#articledetails .back-button-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#articledetails .back-button {
	background-color: transparent;
	color: #FBFCFB;
	padding: 10px;
	font-family: GreycliffCF-Bold;
	cursor: pointer;
}

#articledetails .free-trial {
	padding: 14px 20px;
  opacity: 0.9;	
  background-color: transparent;
  font-family: GreycliffCF-Bold;
  display: flex;
	align-items: center;
	color: #FFFFFF;
  justify-content: center;
	cursor: pointer;
	border: 1px solid #FFFFFF;
  box-shadow: 0 2px 25px 0 rgba(0,0,0,0.1);
  transition: all .5s;
}

#articledetails .kiri-yoga-logo {
  position: absolute;
  top: 10px;
  left: 160px;
}

#articledetails .free-trial:hover {
  color: #000;
  background-color: #FFFFFF; 
}

#articledetails .banner-img { width: 100%; }

#articledetails .banner-title {
	width: 800px;
	margin: auto;
	margin-top: 50px;
}

#articledetails .social-links {
  display: flex;
  justify-content: space-around;
  margin: 50px auto;
  width: 150px;
}

#articledetails .divider {
  height: 1px;
  width: 1080px;
  opacity: 0.1;
  background-color: #000000;
  margin: 1.925VW 13.208333333333334VW 0;
}