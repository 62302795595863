/* Mobile responsive ********************/
@media screen and (min-width: 320px) and (max-width: 767px) {
  #home .ele-block { 
    padding: 40px 20px 30px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  #home .ic-desc { width: auto; padding: 0 30px; }
  #home .ic-desc br { display: none; }
  #home .service-block, #home .ser-types-block { display: block; padding: 0; }
  #home .ser-img-block { display: block; }
  #home .con-flex-row { display: block; text-align: center; }
  #home .first-name, #home .last-name, #home .email, #home .phone { width: 300px; }
  #home .k-dropdown { width: 310px !important; }
  #home .city { margin-top: 1.0416666666666665VW; }
  #home .comment { margin-top: 5px; width: 310px; height: 100px; }
  #home .email, #home .first-name { margin-right: 0; }
  #home .service-col, #home .service-col:first-child, #home .service-col:last-child { width: auto; padding: 20px 40px; }
  #home .diff-block { padding: 40px 20px; }
  #home .service-desc, #home .diff-service-desc { width: 100%; }
  #home .ser-type-col, #home .ser-type-col:first-child, #home .ser-type-col:last-child { width: auto; padding: 20px; }
  #home .ser-img-box { width: 100%; margin: 0; }
  #home .diff-block, #home .our-loc-blok { padding: 40px 20px; }
  #home .city-blk { width: auto; padding: 10px 0; }
  #home .contact-flex-box { padding: 30px 0; }
  /* #home .back-leaf-right, #home .back-leaf-left { width: 100%; } */
  #home .back-leaf-right { top: 30px; width: 100px; }
  #home .back-leaf-left { bottom: -153px; width: 100px; }
  #home .flower-left { height: 100px; width: 115px; left: 20px;}
  #home .flower-right {height: 150px; width: 150px; right: 20px;}
  #home .service-title { margin-top: 10px; }
  #home .city { margin-right: 0; }
  #home .con-comment-box { text-align: center; }
}
 
/* Tablet/Ipad responsive *****************/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #home .ele-block {
    width: auto;
    padding: 50px 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  #home .flower-left { width: 160px; }
  #home .flower-right { width: 190px; }
  #home .service-block { display: block; padding: 30px 40px; }
  #home .service-col:first-child,
  #home .service-col:last-child,
  #home .service-col {
    padding: 20px 20%;
    display: block;
    margin: 0 auto;
  }
  #home .ic-desc { width: 70%; }
  #home .ser-types-block { display: block; padding: 30px 40px; }
  #home .ser-type-col:first-child,
  #home .ser-type-col:last-child,
  #home .ser-type-col { 
    padding: 20px 20%;
    margin: 0 auto;
  }
  #home .diff-block { padding: 50px 40px 0; }
  #home .ic-ser-title { font-size: 1.6458333333333333VW !important; }
  #home .ic-desc br { display: none; }
  #home .city-blk { width: auto; padding: 0 15%; }
  #home .our-loc-blok { padding: 50px 40px 0; }
  #home .contact-flex-box { padding: 50px 0; }
  #home .img-ser { width: 100%; height: 140px; }
  #home .ic-ser-title { padding: 8px 0; }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  #home .ic-ser-title { font-size: 19px !important; letter-spacing: 3.5px; }
}

@media screen and (min-width: 1700px) and (max-width: 1900px) {
  #home .service-block, #home .ser-types-block { padding: 0 15VW; }
}
@media screen and (min-width: 1950px) and (max-width: 3000px) {
  #home .service-block, #home .ser-types-block { padding: 0 18VW; }
}