#membership {
  overflow: hidden;
}

#membership .content-blk {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
}

#membership .side-img-blk {
  position: absolute;
  bottom: 125px;
  right: -65px;
}

#membership .side-img-blk img { width: 85%; }

#membership .intro-content {
  width: 85%;
  color: rgba(23, 23, 23,0.5);
  font-family: OpenSans-Regular;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin: 0;
  padding-bottom: 30px;
}

#membership .content-blk .content-inner-blk {
  width: 840px;
  margin: 80px;
  z-index: 1;
}

#membership .content-blk .content-inner-blk .plan-blk {
  margin-top: 50px;
  display: inline-block;
  text-align: center;
}

#membership .content-blk .content-inner-blk .price-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#membership .content-blk .content-inner-blk .hr-line {
	height: 1px;
	width: 200px;
  background-color: #D6B981;
  margin: 15px 0;
}

#membership .content-blk .content-inner-blk h3 {
	color: #000000;
	font-family: Prata-Regular;
	font-size: 22px;
  letter-spacing: 4px;
  text-align: center;
  text-transform: uppercase;
  margin: 15px 0;
  padding: 5px 0;
}

#membership .content-blk .content-inner-blk .descp {
	width: 85%;
	opacity: 0.5;
	color: rgba(23, 23, 23, 0.6);
	font-family: OpenSans-Regular;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin: 0;
  padding: 10px 0;
}

#membership .plan-outer {
  display: inline-block;
  margin: 20px 10px;
  padding: 0 10px;	
  width: 240px;
  /* background-color: #0eb7da */
}

#membership .plan-inner {
  position: relative;  
  width: 240px;
  height: 280px;
}
/* #membership .plan-outer:hover { */
  /* display: inline-block;
  margin: 20px 10px;
  padding: 0 10px;	
  width: 240px; */
  /* background-color: #0eb7da */
/* } */

#membership .plan-inner::after{
  position: absolute;
  top: 0;
  left: 10px;
  right: 0;
  content: '';
  height: 0;
  width: 0;
  opacity: 0;
  /* border-bottom-right-radius: -7px; */
  border: solid 1px #FFFFFF;
  /* border-top-color:  #D6B981;   */
  /* border-right-color: #D6B981 ; */
  /* border-left-color:  #0eb7da 0px; */
  /* border-bottom-color: #0eb7da 0px ; */
  z-index: -1;
 }  

 #membership .plan-inner:hover::before,
 #membership .plan-inner:hover::after {
  width: 100%;
  height: 100%;
  top: -12px;
  right: -12px;
  opacity: 1;
 }

 /* #membership .plan-inner:hover::before { */
  /* border-top-color: #0eb7da;
  border-right-color: #0eb7da; */ 
  /* border-top-color:  #D6B981;  */
  /* border-right-color: #D6B981 ; */
  /* transition: width 0s ease; */
 /* } */
 
#membership .plan-inner:hover::after {  
  border-top-color:  #D6B981; 
  border-right-color: #D6B981 ;
  /* transition: height 1s ease-in-out , width 1s ease-in-out; */
  transition:  width 0.4s ease-out 0s, height .6s ease-out .4s;
}

#membership .plan-card-base {
  position: absolute;
  width: 240px;
	box-sizing: border-box;
  border: 1px solid #D6B981;  ;
  background-color: #FFFFFF;
  cursor: pointer;
}

#membership .plan-card-base .title-blk {
  background-color: #D6B981;
}

#membership .plan-card-base .title-blk p {
  color: #FFFFFF;
  font-family: OpenSans-Regular;
  font-size: 16px;
  letter-spacing: 0.57px;
  margin: 0;
  padding: 20px 0;
  text-align: center;
  text-transform: uppercase;
}

#membership .plan-card-base .price-blk {
  border-bottom: 1px solid #D6B981;
}

#membership .plan-card-base .price-blk p {
  margin: 0;
  padding-top: 10px;
  text-align: center;
}

#membership .plan-card-base .price-blk p .price-sym {
  color: #000000;
  font-family: Prata-Regular;
  font-size: 15px;
  line-height: 38px;
  vertical-align: top;
}

#membership .plan-card-base .price-blk p .price {
  color: #000000;
  font-family: Prata-Regular;
  font-size: 42px;
  text-transform: uppercase;
  line-height: 68px;
  vertical-align: top;
 }

 #membership .plan-card-base .menu-blk {
  padding: 30px;
 }

 #membership .plan-card-base .menu-blk ul {
   padding: 0;
 }

 #membership .plan-card-base .menu-blk li {
	opacity: 0.6;
	color: #000000;
	font-family: OpenSans-Regular;
	font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

#membership .conditions-apply-to {
	opacity: 0.5;
  color: #000000;
  text-align: center;
	font-family: OpenSans-Regular;
	font-size: 13px;
	font-style: italic;
	letter-spacing: 0.2px;
  line-height: 18px;
  margin: 10px 0 120px 0;
}

#membership .terms-cond {
  margin: 20px auto;
  width: fit-content;
  cursor: pointer;
  /* border-bottom: 1px solid; */
  font-family: OpenSans-Regular;
  font-size: 11px;
  color: rgba(0,0,0,0.6);
  letter-spacing: 0.5px;
}

#membership .terms-cond:hover { color: #B6934E; text-decoration: underline; }
