#pnf-container {
  width: 100%;
  height: 100%;
  background-image: url('/assets/images/page-not-found.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  display: flex;
  align-items: center;
}

#pnf-container .pnf-block {
  margin-left: 10%;
}

#pnf-container .pnf-title {
  font-family: Muli-SemiBold;
  font-size: 170px;
  color: #333333;
  line-height: 214px;
}

#pnf-container .pnf-desc {
  font-family: Muli-Regular;
  font-size: 22px;
  color: rgba(0,0,0,0.6);
  line-height: 34px;
}

#pnf-container .pnf-btn {
  width: 240px;
  height: 48px;
  font-family: Muli-ExtraBold;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 20px;
  padding: 14px 0;
  margin-top: 65px;
  text-align: center;
  border-radius: 3px;
  background-color: #7CA049;
  cursor: pointer;
}

#pnf-container .pnf-btn:hover {
	box-shadow: 0 10px 20px 0 rgba(124,160,73,0.15);
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  #pnf-container .pnf-title {
    font-size: 120px !important;
    line-height: 140px !important;
  }

  #pnf-container .pnf-block {
    margin-left: 5% !important;
    margin-right: 5% !important;
    text-align: center !important;
  }

  #pnf-container .pnf-btn {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}