@media screen and (min-width: 320px) and (max-width: 767px) {
  #ourservices { padding-top: 40px; }
  #ourservices .banner-block { padding: 45px 0 0; }
  #ourservices .listofservice { padding-top: 60px; }
  #ourservices .services-list { 
    width: calc(100% - 20px);
    margin: 20px auto;
  }
  #ourservices .service-list {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 25px 0 30px;
    
  }

  #ourservices .serv-content { width: 280px; margin: auto; }
  #ourservices .serv-img-block { margin: auto; overflow: hidden;}

  #ourservices .serv-cost { 
    display: inline-block;
    width: fit-content; 
    margin: auto;
    padding: 20px 5px;
  }

  #ourservices .leaf-left { height: auto; width: 200px; }
  #ourservices .serv-btn-outer { margin: auto; }
  #ourservices .serv-title {margin-top: 6px; }
  #ourservices .serv-divider { display: none; margin: 0 5px; }
  #ourservices .service-type { font-size: 14px; }
  #ourservices .cost-time-space { display: inline-block; padding: 5px; }
  #ourservices .service-leaf { display: none; }
  #ourservices .sectionhead .expand-icon { width: 19px; }
  #ourservices .input-container { margin: 10px 0 10px 5px; }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #ourservices .serv-divider { margin: 0 5px; }
  #ourservices { padding-top: 7.083333VW }
  #ourservices .services-list { width: 80%; }

  #ourservices .service-list {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  #ourservices .serv-img-block { display: inline-block; }
  #ourservices .serv-desc { width: 100%; }
  #ourservices .serv-btn-outer { margin: 20px auto; }
  #ourservices .serv-cost { justify-content: center; }  
  /* #ourservices .loc-select {right: 0.78125VW !important; } */
  #ourservices .section:last-child .serv-divider { width: 87%; }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  #ourservices .banner-block { padding: 9.508333VW 3.125VW 0; }
}

@media screen and (min-width: 1600px)  {
  /* #ourservices .banner-block {
      height: 900px;
  }  */
  #ourservices { padding-top: 0; }
  #ourservices .serv-desc{ width: 35VW; }
  #ourservices .services-list { width: 60%; }
}