#ccav-block {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#ccav-block .ccav-box {
  margin-top: 60px;
}

#ccav-block .ccav-succ-title {
  font-family: OpenSans-Bold;
  font-size: 28px;
  color: #000;
  letter-spacing: 6px;
  line-height: 26px;
}

#ccav-block .ccav-succ-sub-title {
  font-family: OpenSans-Regular;
  font-size: 16px;
  color: #000;
  letter-spacing: 3px;
  line-height: 16px;
  padding: 30px 0 15px;
  opacity: 0.6;;
}

#ccav-block .ccav-go-back {
  font-family: OpenSans-Regular;
  font-size: 16px;
  color: #000;
  letter-spacing: 2px;
  line-height: 16px;
}

