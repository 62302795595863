/* Mobile Resppnsive */
@media screen and (min-width: 320px) and (max-width: 767px) {
  #overlay .overlay-body.corefit-sel .close-img {
    top: 20px !important;
    right: 20px !important;
  }

  #corefit .corefit-desc { width: auto; padding: 0 10px !important;}
}

/* Tablet/IPad responsive */
@media screen and (min-width: 768px) and (max-width: 1023px) {
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  #corefit .corefit-desc {
    width: fit-content;
  }

}

