#home .ele-block {
  position: relative;
  width: 100%;
  /* height: 26.041666666666668VW; */
  padding: 7.291666666666667VW 0 6.770833333333333VW;
  /* background-image: url('/assets/images/img_flowers.jpg'); */
  text-align: center;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home .flower-right {
  background-image: url('/assets/images/ic_flower1-1.png');
  text-align: center;
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100%;
  background-position: bottom;
  height: 300px;
  width: 350px;
  right: 30px;
  bottom: 15px;
}

#home .flower-left {
  background-image: url('/assets/images/ic_flower1-2.png');
  text-align: center;
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100%;
  background-position: bottom;
  height: 300px;
  width: 260px;
  left: 40px;
  bottom: 15px;
}

#home .ele-cup-block {
  text-align: center;
}

#home .ic-divider {
  /* height: 1.1px; */
  width: 200px;
  /* background: #D6B981; */
  margin: 15px auto 0;
  border-bottom: 1px solid #D6B981
}

#home .ic-title {
  font-family: Prata-Regular;
  font-size: 22px;
  color: #000000;
  letter-spacing: 4px;
  line-height: 36px;
  margin-top: 1.5625VW;
  text-align: center;
  text-transform: uppercase;
}

#home .ic-desc {
  font-family: OpenSans-Light;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.5px;
  line-height: 26px;
  text-align: center;
  width: 670px;
  margin: 20px auto;
}

#home .service-block {
  background: rgba(248, 249, 246, 0.65);
}

#home .service-block,
#home .ser-types-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10VW;
  /* 380px */
}

#home .ser-types-block {
  padding: 3.3854166666666665VW 18VW;
}

#home .service-col {
  width: 260px;
  padding: 4.166666666666666VW 1.25VW;
}

/* #home .service-col:first-child { padding-left: 0; } */
/* #home .service-col:last-child { padding-right: 0; } */

#home .service-title {
  font-family: OpenSans-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 2px;
  line-height: 19px;
  text-align: center;
  margin-top: 26px;
  white-space: nowrap;
  text-transform: uppercase;
}

#home .service-desc,
#home .diff-service-desc {
  height: 110px;
  font-family: OpenSans-Light;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.3px;
  line-height: 22px;
  text-align: center;
  margin-top: 15px;
}

#home .service-desc {
  width: 250px;
}

#home .diff-service-desc {
  width: 270px;
}

#home .diff-block,
#home .our-loc-blok {
  padding: 7.291666666666667VW 0 6.770833333333333VW;
}

#home .ser-type-col {
  width: 260px;
}

#home .ser-img-block {
  position: relative;
  width: 100%;
  display: flex;
}

#home .ser-img-box {
  width: 25%;
  overflow: hidden;
  cursor: pointer;
}

#home .img-ser {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: 0.9s ease;
}

#home .ser-container {
  transition: 0.9s ease;
  /* height: 100%; */
  width: 100%;
  position: relative;
  display: flex;
  white-space: nowrap;
}

#home .ser-img-box:hover .img-ser {
  transform: perspective(1px) scale(1.08);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* #home .ser-flex-center { opacity: 0.8; } */

#home .ser-content-box {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home .ic-ser-title {
  font-family: Prata-Regular;
  color: #FFFFFF;
  font-size: 22px;
  letter-spacing: 4px;
  line-height: 36px;
  text-align: center;
  padding: 1.9791666666666665VW 0;
  text-transform: uppercase;
}

#home .ic-ser-divider {
  height: 0.072083333333333336VW;
  width: 150px;
  /* background-color: #FFFFFF; */
  margin: 0 auto;
}

#home .treat-expand-line {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 20px; */
}

#home .treat-expand-line::after {
  content: '';
  position: absolute;
  width: 150px;
  transform: scaleX(0.5);
  height: 1px;
  /* bottom: 0; */
  background-color: #FFFFFF;
  transform-origin: bottom center;
  transition: transform 0.45s ease-in-out;
}

#home .ser-img-box:hover .treat-expand-line::after {
  transform: scaleX(1);
  transform-origin: bottom center;
}

#home .diff-block {
  padding: 7.291666666666667VW 0 6.770833333333333VW;
}

#home .city-blk {
  margin: 0 auto;
  height: 220px;
  width: 759px;
}

#home .contact-block {
  position: relative;
  width: 100%;
  /* height: 40.10416666666667VW; */
  /* background-image: url('/assets/images/img_banner_leaf.jpg');
  text-align: center;
  background-size: cover; */
  background: rgba(248, 249, 246, 0.65);
  display: flex;
  justify-content: center;
  z-index: 2;
  /* align-items: center; */
}

#home .contact-flex-box {
  padding: 5.729166666666666VW 0;
}

#home .con-fileds-block {
  margin-top: 2.604166666666667VW;
}

#home .con-flex-row {
  display: flex;
  /* justify-content: space-between; */
}

#home .first-name,
#home .last-name,
#home .email,
#home .phone {
  height: 54px;
  width: 25.520833333333332VW;
  border: 1px solid #E3E3E3;
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.6);
  font-family: OpenSans-Regular;
  font-size: 14px;
  padding: 0 1.0416666666666665VW;
}

/* margin-right: 2.083333333333 */
#home .first-name {
  margin-bottom: 1.0416666666666665VW;
  margin-right: 2.083333333333333VW;
}

#home .email {
  margin-bottom: 1.0416666666666665VW;
  margin-right: 2.083333333333333VW;
}

#home .city {
  margin-right: 2.083333333333333VW;
  margin-bottom: 1.0416666666666665VW;
}

#home .last-name {
  margin-bottom: 1.0416666666666665VW;
}


#home .comment {
  box-sizing: border-box;
  font-family: OpenSans-Regular;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  height: 160px;
  width: 57.591666666666664VW;
  border: 1px solid #E3E3E3;
  background-color: #FFFFFF;
  padding: 1.14VW 1.0416666666666665VW;
}


#home .back-leaf-right {
  background-image: url('/assets/images/leaf_right1.png');
  height: 260px;
  width: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 80px;
  z-index: -1;
}

#home .back-leaf-left {
  background-image: url('/assets/images/leaf_left1.png');
  height: 260px;
  width: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  bottom: -49px;
  z-index: -1;
}

/* #home .con-comment-box { padding-bottom: 5.729166666666666VW; } */

#home .outer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 150px;
  border: 1px solid #D6B981;
  margin: 1.5625VW auto 0;
  padding: 3px;
  cursor: pointer;
}

#home .submit-btn-inner {
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  font-family: OpenSans-Bold;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 19px;
  text-align: center;
  background: #D6B981;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: all 0.1s ease-in-out;
}

#home .submit-btn-inner:hover {
  background-color: transparent;
  color: #D6B981;
}

#home .ban-divider-img {
  background: url(/assets/images/ic_dwells_text.svg) no-repeat top left;
  background-position: center;
  width: 20px;
  height: 20px;
  padding-left: 19px;
  padding-bottom: 0px;
}

#home .form-field {
  height: 54px;
  width: 25.520833333333332VW;
  border: 1px solid #E3E3E3;
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.6);
  font-family: OpenSans-Regular;
  font-size: 14px;
  padding: 0 1.0416666666666665VW;
}

#home .form-field.error {
  border: 1px solid red;
}

#home .k-dropdown.error {
  border: 1px solid red;
}

#home .comment.error {
  border: 1px solid red;
}

#home .k-dropdown .k-dropdown-wrap .k-input {
  height: 100% !important;
  font-family: OpenSans-Regular;
  font-size: 14px;
  padding: 4px 1.0416666666666665VW;
}

#home .k-dropdown .k-dropdown-wrap {
  background-color: #fff;
  background-image: none;
}

#home .k-dropdown,
.k-dropdowntree {
  width: 27.820833333333332VW !important;
  height: 4em !important;
}

#home .cls-franchise {
  font-family: OpenSans-Regular;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  padding: 60px 0 0;
  letter-spacing: 0.6px;
  text-align: center;
}

#home .cls-franchise span {
  color: #B6934E;
  text-decoration: underline;
  cursor: pointer;
}