@media screen and (min-width: 320px) and (max-width: 767px) {
  #giftvoucher .banner { 
    background-size: cover !important;
    background-position: right !important;
  }
  #giftvoucher .banner-card { height: 250px; width: 300px; margin-right: 0; }
  #giftvoucher .ic-desc { width: 70%; margin: auto; }
  #giftvoucher .gift-service-card, .gift-voucher-card { height: 150px; width: 250px; }
  #giftvoucher .gift-block { padding-top: 40px; }
  #giftvoucher .gift-banner { padding-bottom: 30px; }
  #giftvoucher .gift-card-block { margin: 40px auto 0; }
  #giftvoucher .cactus-back-left { width: 80px; }
  #giftvoucher .cactus-back-right { width: 150px; bottom: -48px; }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #giftvoucher .banner { background-size: cover; background-position: right; }
  #giftvoucher .gift-block { 
    width: 70%;
    padding: 50px 40px;
    margin: 0 auto; 
  }
  #giftvoucher .ic-desc { width: 100%; }
  #giftvoucher .gift-card-block { margin: 0; }
  #giftvoucher .gift-voucher-card, #giftvoucher .gift-service-card { width: 280px; height: 180px; }
  #giftvoucher .banner-card {
    background-size: 100%;
    height: 285px;
    width: 350px;
    margin-top: 0;
    margin-right: 0;
  }
  #giftvoucher .cactus-back-left { width: 145px; }
}