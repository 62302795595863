#ourservices  {
  /* padding-top: 2.083333333333333VW; */
  position: relative;
}
  
#ourservices .banner-block {
  /* background-image: url('/assets/images/img_ban_services.jpg'); 
  background-size: 100% auto; */
  /* height: 100vh ;  
  width: 100%;
  position: relative; */
  /* margin-bottom: 30px; */  
  /* display: flex; */
  padding: 8.508333333333334VW 3.125VW 0;
  /* height: 95vh; */
}

#ourservices .banner-img { height: auto; width: 100%; }

#ourservices .masques-time { display: flex; font-size: 16px; }

/* tool tip starts */
#ourservices .tooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

#ourservices .tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #D6B981;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -15px;
  left: 30px;
  /* right: 115 */
  font-weight: 800;
  font-family: OpenSans-Regular;
  font-size: 12px;
}

#ourservices .tooltip:hover .tooltiptext {
  visibility: visible;
}
/* tool tip ends */

/* #ourservices .serv-col1 {
  width: 50%;
  height: 100%;
  margin-right: 4px;
}

#ourservices .serv-col1 .row1,
#ourservices .serv-col2 .col2 .row1 {
  height: calc(50% - 4px);
  margin-bottom: 4px;
  overflow: hidden;
  position: relative;
}


#ourservices .serv-col1 .row2 { 
  display: flex; 
  height: 50%; 
  overflow: hidden;
  position: relative; 
}

#ourservices .serv-col1 .row2 .col1,
#ourservices .serv-col2 .col1 { 
  width: 50%; 
  margin-right: 4px; 
  overflow: hidden; 
  position: relative;
}

#ourservices .serv-col1 .row2 .col2,
#ourservices .serv-col2 .col2 { 
  width: 50%; 
  overflow: hidden;
  position: relative; 
}

#ourservices .serv-col2 { display: flex; width: 50%; overflow: hidden; } */

/* #ourservices .serv-col2 .col1 { width: 50%; margin-right: 4px; } */

/* #ourservices .serv-col2 .col2 { width: 50%; } */

/* #ourservices .serv-col2 .col2 .row1 { height: calc(50% - 4px); margin-bottom: 4px; } */

/* #ourservices .serv-col2 .col2 .row2 { 
  height: 50%; 
  overflow: hidden;
  transition: 0.3s ease;
  position: relative;
}

#ourservices .service-content {
  height: 100%;
  width: 100%;
  transition: 0.9s ease-in-out;
}

#ourservices .service-content:hover { transform: perspective(1px) scale(1.07); }

#ourservices .body-scrup,
#ourservices .foot-therap,
#ourservices .head-face,
#ourservices .mani-pedi,
#ourservices .body-masques,
#ourservices .body-mass {
  color: #FFFFFF;	
  font-family: Prata--Regular;
  font-size: 24px;
  letter-spacing: 3px;
  line-height: 35px;
  text-align: center;  
}

#ourservices .body-scrup,
#ourservices .foot-therap,
#ourservices .head-face,
#ourservices .mani-pedi,
#ourservices .body-masques {
  position: absolute;
  width: 100%;
  bottom: 30px;
}

#ourservices .body-mass {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0  
} */

#ourservices .services-list {
  width: 70%;
  margin: auto;
  margin-top: 70px;
  padding-bottom: 7.8125VW;
  min-height: 15.833333333333336VW;
  position: relative;
}

#ourservices .loc-select {
  display: flex;
  position: absolute;
  /* margin-top: 30px; */
  right: 0;
}

#ourservices .input-container { margin: 10px 5px; }

#ourservices .input-container .input-groups .field {  
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  opacity: 0.6; 
}
#ourservices .k-dropdown {
  width: 150px;
  margin-left: 10px ;
}

#ourservices .input-container .input-groups {
  display: flex;
  align-items: center;
} 

#ourservices .section {
  position: relative;
  /* border:1px solid #D5B981; */ 
  width: 100%;
  margin-top: 2.3958333333333335VW;  
}

/* #ourservices .section:first-child { margin-top: 0px; } */

#ourservices .section.open .sectionbody {    
  /* height: auto; */
  overflow-x: auto;
  /* overflow-y: auto;     */
  transition: height 1s ease-in-out;  
  overflow: hidden;
}

#ourservices .sectionbody {
  height: 0px;
  overflow: hidden;
  transition: height 0.9s ease-in-out;    
}

#ourservices .sectionhead {
  overflow: hidden;  
  display: flex;
  cursor: pointer;    
  position: relative;  
  justify-content: space-between;
  align-items: center;
}

#ourservices .service-type { 
  color: #000000;	
  font-family: Prata-Regular;	
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 26px;
  white-space: nowrap;
  text-transform: uppercase;
}

#ourservices .serv-divider {
  height: 1.1px;
  width: 100%;
  opacity: 0.1;
  background-color: #000000;
  margin: 0 20px;
}

#ourservices .sectionhead .expand-icon {
  width: 30px;
  height: 19px; 
  background: url('/assets/images/sprint_aroma_icons.svg') no-repeat -252px -15px;  
}

#ourservices .section.open .sectionhead { color: #FFFFFF; }

#ourservices .section.open .sectionhead .expand-icon { background: url('/assets/images/sprint_aroma_icons.svg') no-repeat -286px -15px; }

#ourservices .article {
  padding: 1em;
  color: #333;
  line-height: 1.3;
}

#ourservices .service-list {
  display: flex;
  /* margin-top: 25px; */
  padding: 25px 20px 30px;
  border-bottom: 1px solid #D6B981;
}

#ourservices .listofservice { padding-top: 50px; }

#ourservices .service-list:last-child { border-bottom: none; }

#ourservices .serv-content { margin-left: 20px; width: 600px; }

#ourservices .serv-img-block { height: 200px;	width: 300px; }

#ourservices .serv-img { width: 100%; height: auto; }

#ourservices .serv-title {
  color: #000000;
  font-family: OpenSans-Regular;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 22px;
  text-transform: uppercase;
}

#ourservices .serv-physio {
  opacity: 0.7;	
  color: #000000;
  font-family: OpenSans-Light;
  font-size: 14px;
  letter-spacing: 0.26px;
  line-height: 19px;
  margin-top: 6px;
}

#ourservices .serv-desc {
  color: #000000;
  font-family: OpenSans-Light;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 22px;
  margin-top: 11px;
}

#ourservices .serv-cost {
  color: rgba(0,0,0,0.8);
  font-family: Prata-Regular;
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 17px;
  margin-top: 11px;
  display: flex;
}

#ourservices .cost-rupee {
  font-size: 16px;
  color: #000000;
  font-family: Prata-Regular;
  letter-spacing: 2px;
  padding-left: 3px;
}

#ourservices .addon-desc {
  font-size: 15px;
  color: #000000;
  font-family: OpenSans-Regular;
  letter-spacing: 1.5px;
  padding-left: 3px;
  
}

#ourservices .addon-block {
  display: flex;
  margin-top: 11px;
  align-items: center;
}

#ourservices .Addondet {
  color: #000000;
  font-family: OpenSans-Regular;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 22px;
  text-transform: uppercase;
  cursor: pointer;
}

#ourservices .cost-slash { font-size: 16px; }

#ourservices .rupee-sym { color: #D6B981; font-size: 16px; }

#ourservices .serv-btn-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 150px;
  border: 1px solid #D6B981;
  margin-top: 14px;
  padding: 2px;
  cursor: pointer;
}

#ourservices .serv-btn-outer.disable {
  border: 1px solid gray;
}

#ourservices .serv-btn-inner { 
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  font-family: OpenSans-SemiBold;
  font-size: 14px;	
  letter-spacing: 1.5px;
  line-height: 19px;
  text-align: center;
  background: #D6B981;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: all 0.1s ease-in-out;
}

#ourservices .serv-btn-inner:hover { 
  background-color: transparent;
  color: #D6B981;
}

#ourservices .serv-btn-inner.disable { 
  background-color: gray;
  color: black;
  cursor: default;
}

#ourservices .serv-btn-inner.disable:hover { 
  background-color: gray;
  color: black
}


#ourservices .service-leaf { position: relative; }

#ourservices .leaf-left {
  height: 200px;
  width: 230px;
  background-image: url('/assets/images/img_leaf_left.png');
  background-size: 100% 100%; 
  position: absolute;
  bottom: 0;
  z-index: -1;
}

#ourservices .leaf-right {
  height: 200px;
  width: 200px;
  background-image: url('/assets/images/img_leaf_right.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 100px;
  z-index: -1; 
}

#ourservices .coming-soon {
  font-family: OpenSans-Regular;
  font-size: 28px;
  text-align: center;
  min-height: 28VW;
  align-items: center;
  display: flex;
  justify-content: center;
}

#ourservices .cost-time-space { padding-right: 20px; }

#ourservices .k-dropdown .k-dropdown-wrap { font-family: OpenSans-Regular; }
