@media screen and (min-width: 320px) and (max-width: 767px) { 
  #membership #banner-blk .banner-content {
    background: none !important;
  }
  #membership .content-blk .content-inner-blk {
    width: auto;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  #membership .content-blk .content-inner-blk .plan-blk { margin-top: 30px; }
  #membership .conditions-apply-to { margin: 0 auto 40px auto }
  #membership .side-img-blk { display: none; }
  #membership .banner-btn-outer { margin-top: 30px; }
  #membership .terms-cond { margin-top: 0; }
}

@media screen and (min-width: 768px) and (max-width: 1023px) { 
  #membership .content-blk .content-inner-blk { margin: 50px 40px; }
  #membership-container .page-note-container { 
    text-align: left;
    width: 75%;
    margin: 0 auto;
  }
}