#banner-blk {
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: 100% 100%; */
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#banner-blk .banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#banner-blk .content-title {
  color: #000000;
  font-family: Prata-Regular;	
  font-size: 48px;
  letter-spacing: 10px;
  line-height: 78px;
  text-align: center;
  text-transform: uppercase;
}

#banner-blk .content-desc {
  width: 635px;   
  color: rgba(0, 0, 0, 0.7);
  font-family: OpenSans-Light;	
  font-size: 20px;   	
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: center;
  margin-top: 0.9375VW;
}

#banner-blk .rm-margin {
  margin-top: 0;
}

#banner-blk .media-logo {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 38px;	
  width: 38px;
  cursor: pointer;
}

#banner-blk .social-media {
  display: flex;  
  justify-content: center;
  margin-top: 2.34375VW;
}

#banner-blk .ic-insta { background: url(/assets/images/sprint_aroma_icons.svg) no-repeat -78px -15px; }

#banner-blk .ic-insta:hover { background: url(/assets/images/sprint_aroma_icons.svg) no-repeat -580px -15px; }

#banner-blk .ic-facebook { background: url(/assets/images/sprint_aroma_icons.svg) no-repeat -131px -15px; margin-left: 1.0416666666666665VW }

#banner-blk .ic-facebook:hover { background: url(/assets/images/sprint_aroma_icons.svg) no-repeat -633px -15px;}
