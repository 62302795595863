
#review-carousel .carousel-block {
  position: relative;
}

#review-carousel .slideshow-container {
  position: relative;
  margin: auto;
}
/* 
#review-carousel .carousel-flex {
  display: flex;
  justify-content: center;
  align-items: center;
} */

#review-carousel .carousel-flex { overflow: hidden; }

#review-carousel .our-valued-customers {
  font-family: Prata-Regular;
    font-size: 22px;
    color: #000000;
    letter-spacing: 4px;
    line-height: 36px;
    margin: 1.5625VW 0;
    text-align: center;
    text-transform: uppercase;
}

#review-carousel .hide-div {
  display: none;
}

#review-carousel .carousel-img {
  width: 100%;
  height: 450px;
  background-image: url('/assets/images/img_review_carousel.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* padding: 2.729166666666666VW 0; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

/* #review-carousel .desc-block {
  position: absolute;
} */

#review-carousel .desc-title { 
  font-family: Prata-Regular;
  font-size: 22px;
  color: #000000;
  letter-spacing: 4px;
  line-height: 36px;
  text-align: center;
  padding-top: 1.3541666666666667VW;
  text-transform: uppercase;
}

#review-carousel .desc-content {
  width: 635px;
  font-family: OpenSans-Light;
  color: rgba(0,0,0,0.6);
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 26px;
  margin: 18px auto 0;
  text-align: center;
}

#review-carousel .auth-title {
  font-family: OpenSans-Regular;
  font-size: 16px;
  color: #000000;
  letter-spacing: 1.14px;
  line-height: 22px;
  text-align: center;
  padding-top: 2.083333333333333VW;
  text-transform: uppercase;
}

#review-carousel .auth-role {
  font-family: OpenSans-light;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  line-height: 19px;
  text-align: center;
  padding-top: 0.3125VW;
}

#review-carousel .circle-row { 
  position: absolute;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 75px;
  /* bottom: 3.604166666666667VW; */
  text-align: center;
 }

 #review-carousel .circle-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 10px;
  border: 1px solid #D6B981;
  border-radius: 100%;
  margin: 0 4px;
 }

 #review-carousel .circle {
  padding: 3px;
  cursor: pointer;
  border-radius: 100%;
  transition: background-color 0.4s ease;
  cursor: pointer;
  box-sizing: content-box; 
}

#review-carousel .circle:hover, .active { background-color: #D6B981; }

/* Carousel fading animation - start */
#review-carousel .fade {
  width: 80%;
  margin: 0 auto;
  /* -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s; */
}
/* @-webkit-keyframes fade { from {opacity: .8} to {opacity: 1} }
@keyframes fade { from {opacity: .8} to {opacity: 1} } */
/* Carousel fading animation - end */

