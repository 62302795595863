/*Booking overlay Css*/
#ourlocation-container{
  height: 100%;
  width:100%;
  margin: 0 auto;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}
#ourlocation-container .wrapper { padding-top:0px !important; }

/*Accordion tab style*/

#ourlocation-container .main {
  width: 90%;
  margin: 0 auto ;
  overflow: hidden;
}

#ourlocation-container .section {
  position: relative;
  border:1px solid #D5B981;
  margin:10px;
}

#ourlocation-container .section.open .sectionbody {
  height: 200px;
  overflow: auto;
}

#ourlocation-container .sectionbody {
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in;
}

#ourlocation-container .sectionhead {
  overflow: hidden;
  /* background: white; */
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding:15px;
  /* padding-right: 2.1em; */
  position: relative;
  color: #000000;
  font-family: OpenSans-Regular;
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 19px;
  text-transform: uppercase;
}
#ourlocation-container .sectionhead .expand-icon{
  width: 11px;
  height: 11px;
  display: inline-block;
  background-image: url("/assets/images/sprint_aroma_icons.svg");
  background-repeat: no-repeat;
  background-position:-465px -15px;
  float: right;
  margin-top: 3px;
}
#ourlocation-container .section.open .sectionhead{
  background-color:#D5B981;
  color: #FFFFFF;    
}
#ourlocation-container .section.open .sectionhead .expand-icon{
  background-position: -439px -14px;
}
#ourlocation-container .article {
  padding: 1em;
  color: #333;
  line-height: 1.3;
}
#ourlocation-container .main { margin: 0 auto 50px; }

/* Media query **************************/
@media screen and (min-width: 320px) and (max-width: 767px) {
  #ourlocation-container .city-address {
     min-height: auto;
     width: 80%;
     margin: auto;
     padding: 10px 0;
  }
  #ourlocation-container .address-block { position: relative; }
  #ourlocation-container .address-block address { min-height: auto; }
  #ourlocation-container .section.open .sectionbody { height: auto !important; }
  #ourlocation-container .main { width: 100%; }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #ourlocation-container .city-address { width: 35%; }
  #ourlocation-container .address-container { width: 100%; }
}
