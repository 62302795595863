#places-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#places-container .our-loc-flex-row-1, 
#places-container .our-loc-flex-row-2 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 3.958333333333333VW;
}

#places-container .our-loc-flex-row-2 {
  margin-top: 0;
  padding-top: 2.604166666666667VW;
}

#places-container .our-loc-flex-box:first-child { padding-left: 0; }

#places-container .ic-loc-title {
  font-family: Prata-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 2px;
  line-height: 23px;
  text-align: center;
  padding-top: 15px;
  text-transform: uppercase;
}

#places-container .our-loc-flex-box {
  margin: 0 7.291666666666667VW;
  cursor: pointer;
}
