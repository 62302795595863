#giftvoucher {  }

#giftvoucher .banner {
  background-image: url('/assets/images/gift_banner.jpg'); 
  /* background-size: 100% 100%; */
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}   

#giftvoucher .banner-card {
  background-image: url('/assets/images/voucher-card.png'); 
  background-size: contain;
  height: 25.833333VW;
  width: 48.708333VW;
  background-repeat: no-repeat;
  margin-top: 60px;
  margin-right: 160px;
}

#giftvoucher .gift-banner { position: relative; padding-bottom: 80px; }

#giftvoucher .gift-block {
  position: relative;
  width: 100%;
  /* height: 26.041666666666668VW;     */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 7.291666666666667VW;
}
  
#giftvoucher .gift-cup-block { text-align: center; }
  
#giftvoucher .ic-divider {
  height: 1px;
  width: 200px;
  border-bottom: 1px solid #D6B981;
  margin: 15px auto 0 ;
}
  
#giftvoucher .ic-title {
  color: #000000;
  font-family: Prata-Regular;
  font-size: 22px;
  letter-spacing: 4px;
  line-height: 36px;
  text-align: center;
  margin-top: 1.5625VW;
  text-transform: uppercase;
}
  
#giftvoucher .ic-desc {
  font-family: OpenSans-Light;
  font-size: 18px;
  color: rgba(0,0,0,0.6);
  letter-spacing: 0.5px;
  line-height: 26px;
  text-align: center;
  margin: 20px auto 0 auto;
  width: 670px;
}

#giftvoucher .gift-card-block {  
  margin: 60px auto 0;   
  text-align: center;   
}

#giftvoucher .gift-service-card, .gift-voucher-card {       
  background-size: cover;
  height: 16.666666666666664VW;	
  width: 27.604166666666668VW;
  cursor: pointer;
  display: inline-block;  
  transform: scale(0.95);
  transition: 0.9s ease;
}


#giftvoucher .gift-service-card:hover, .gift-voucher-card:hover {
  transform: scale(1);
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.15);
}

#giftvoucher .gift-service-card { background-image: url('/assets/images/img_get_service.png'); }

#giftvoucher .gift-voucher-card { background-image: url('/assets/images/img_get_voucher.png'); }

#giftvoucher .cactus-back-left {
  height: 250px;
  width: 320px;
  background-image: url('/assets/images/img_cactus left.png');
  background-size: contain; 
  background-repeat: no-repeat;
  position: absolute;
  top: 10px;
  z-index: -1;
}

#giftvoucher .cactus-back-right {
  height: 105px;	width: 260px;
  background-image: url('/assets/images/img_cactus right.png');
  background-size: contain;
  background-repeat: no-repeat; 
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

#giftvoucher .terms-cond {
  margin: 20px auto;
  width: fit-content;
  cursor: pointer;
  /* border-bottom: 1px solid; */
  font-family: OpenSans-Regular;
  font-size: 11px;
  color: rgba(0,0,0,0.6);
  letter-spacing: 0.5px;
}

#giftvoucher .terms-cond:hover { color: #B6934E; text-decoration: underline; }
