#corefit {
  display: flex;
  align-items: center;
}

#corefit .corefit-block { padding: 30px 0; }

#corefit .corefit-logo {
  /* width: 140px; */
  /* height: 50px; */
  /* background: url(/assets/images/corefit-logo.png); */
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  text-align: center;
}

#corefit .core-logo {
  display: flex;
  align-items: center;

}

#corefit .corefit-title {
  font-family: Prata-Regular;
  font-size: 28px;
  letter-spacing: 5px;
  padding: 30px 0;
  text-align: center;
  color: #B6934E;
}

#corefit .corefit-desc {
  font-family: OpenSans-Regular;
  font-size: 16px;
  line-height: 26px;
  width: 550px;
  margin: 0 auto;
  text-align: center;
  color: rgba(0,0,0,0.6);
}

.overlay-body.corefit-sel { width: 40% !important; margin: 6% auto !important; }

#overlay .overlay-body.corefit-sel .close-img {
  top: 8.525VW !important;
  right: 30.770833VW !important;
}