#awaited {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#awaited .ccav-succ-title {
    font-family: OpenSans-Bold;
    font-size: 28px;
    color: #000;
    letter-spacing: 6px;
    line-height: 26px;
    margin-top: 20px;
  }
  
  #awaited .ccav-succ-sub-title {
    font-family: OpenSans-Regular;
    font-size: 16px;
    color: #000;
    letter-spacing: 3px;
    line-height: 16px;
    padding: 30px 0 15px;
    opacity: 0.6;;
  }