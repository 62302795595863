#cities {
  height: 100%;	
  width: 100%;
}

#cities .our-loc-flex-row-1,
#cities .our-loc-flex-row-2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3.958333333333333VW;
}

#cities .our-loc-flex-row-2 {
  justify-content: space-evenly;
}

#cities .our-loc-flex-box { 
  width: 150px;
  cursor: pointer; 
  
}

#cities .our-loc-flex-box:first-child { padding-left: 0; }
#cities .our-loc-flex-box:last-child { padding-right: 0; }

#cities .ic-loc-title {
  font-family: Prata-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 2px;
  line-height: 23px;
  text-align: center;
  padding-top: 15px;
  text-transform: uppercase;
}

/* #cities .ic-loc-1:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  background: red;
  visibility: hidden;
  transform: scaleX(0);
  transition: .25s linear;
 } 

 #cities .ic-loc-1:hover::after,
 {
  width: 100%;
  visibility: visible;
  transform: scaleX(1);
} */
#cities .loc-expand-line {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 20px; */
}

#cities .loc-expand-line::after {
  content: '';
  position: absolute;
  width: 110px;
  transform: scaleX(0.6);
  height: 2px;
  bottom: 0;
  background-color: #D6B981;
  transform-origin: bottom center;
  transition: transform 0.1s linear;
}

#cities .our-loc-flex-box:hover .loc-expand-line::after {
  transform: scaleX(1);
  transform-origin: bottom center;
}