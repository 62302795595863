@media screen and (min-width: 320px) and (max-width: 767px) {
  #overlay #places-container .wrapper { padding: 0 !important; }
  #overlay .overlay-body { padding: 0 !important; }
  #overlay #places-container .overlay-heading { margin-top: 0 !important; }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #overlay .overlay-body.city-sel { 
    width: 80% !important; 
    height: 60%;
    margin: auto !important;
  }
  #overlay #places-container .wrapper { padding: 0; }
  #cities { width: 80% !important; margin: 0 auto !important; }
  #overlay #places-container .overlay-heading { margin-top: 0 !important; }
}